.container {
  display: flex;
  width: 508px;
  flex-direction: column;
}

.containerHeader{
  display: flex;
  align-items: start;
  margin-bottom: 5px;
}

.containerHeaderInputLeftLTR {
  padding-top: 6px;
  display: flex;
  align-items: center;
}

.containerHeaderInputLeftRTL {
  padding-top: 6px;
  display: flex;
  align-items: center;
}

.containerHeaderInputRightLTR {
  padding-top: 6px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.containerHeaderInputRightRTL {
  padding-top: 6px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.checkBoxIconLTR {
  width: 30px;
  margin-right: 5px;
}

.checkBoxIconRTL {
  width: 30px;
  margin-left: 5px;
}

.optinsBlock{
  display: flex;
  width: 508px;
  flex-wrap: wrap;
}

.title{
  font-family: 'HelveticaNeue';
  font-weight: 200;
  color: #0F1A48;
  font-size: 20px;
  margin-bottom: 40px;
  letter-spacing: 4px;
  margin-top: 50px;
}

.consentCheckbox{
  margin-right: 12px;
}

.consentDescription{
  font-family: 'HelveticaNeue';
  font-weight: 200;
  color: #0C1B54;
  font-size: 12px; 
  letter-spacing: 2px;
}
  
.saveButton{
  height: 54px;
  background-color: #0E1D51;
  color: white;
  font-family: 'HelveticaNeue';
  font-size: 12px;
  letter-spacing: 2px;
  border: none;
  margin-top:81px;
  margin-bottom:60px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
  cursor:pointer;
}

.errorMessageBlock{
  width: 508px;
  margin-top: 15px;
}

.errorMessage{
  color: red;
}

.consentRadioContainer{
  display: flex;
  flex-direction: row;
}

.consentRadioYes{
  display: flex;
  margin-right: 10px;
}

.consentRadioNo{
  display: flex;
  margin-right: 10px;
}

.policyCheckboxContainer{
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 508px;
  margin-top: 30px;
}

.policyCheckbox{

}

.optinsList{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 508px;
}

.consentCheckboxContainerLeft{
  padding-top: 7px;
}

.consentCheckboxContainerRight{
  padding-top: 7px;
  margin-left: 10px;
}

.privacyPolicy{
  color: #0C1B54;
}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    width: 90%;
    flex-direction: column;
  }

  .containerHeader{
    display: flex;
    margin-bottom: 5px;
  }

  .optinsBlock{
    display: flex;
    width: 90%;
    flex-wrap: wrap;
  }

  .errorMessageBlock{
    width: 300px;
    margin-top: 15px;
  }

  .policyCheckboxContainer{
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 90%;
  }


  .optinsList{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title{
    font-family: 'HelveticaNeue';
    font-weight: 200;
    color: #0F1A48;
    font-size: 20px;
    margin-bottom: 40px;
    letter-spacing: 4px;
    margin-top: 50px;
    text-align: center;
    width: 300px
  }

  .consentCheckboxContainer{
  
  }
}
.containerRTL {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    flex-grow: 1;
    direction: rtl;
  }

  .containerLTR {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    flex-grow: 1;
    direction: ltr;
  }

  .headerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 144px;
    background-color: white;
    -webkit-box-shadow: 0 6px 4px -4px #D6D8E1;
    -moz-box-shadow: 0 6px 4px -4px #D6D8E1;
    box-shadow: 0 6px 4px -4px #D6D8E1;
  }

.logo{
  width: 100px;
  margin-top: 40px;
}

.bodyContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.container{
    min-height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.logo{
    width: 175px;
    margin-top: 40px;
}

.icon{
    width: 32px;
    margin-top: 32px; 
}

.title{
    color: #0E1D51;
    font-family: 'HelveticaNeue';
    font-size: 16px;
    letter-spacing: 2px;
    margin-top: 40px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
}

.title{
    font-family: 'HelveticaNeue';
    color: #0F1A48;
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 85px;
    letter-spacing: 4px;
}

.description{
    font-family: 'HelveticaNeue';
    color: #0F1A48;
    font-size: 12.5px;
    margin-bottom: 60px;
    letter-spacing: 0.8px;
}

.btnContainer{
    width: 216px;
    height: 54px;
    background-color: #0E1D51;
    align-items: center;
    justify-content: center;
    display: flex;
}

.btn{
    text-decoration: none;
    color: white;
    font-family: 'HelveticaNeue';
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold; 
}
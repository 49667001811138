.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.container p{
    margin-top: 40px;
}

.title {
    color: #0E1D51;
    font-family: 'HelveticaNeue';
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
}

.description {
    color: #0E1D51;
    font-family: 'HelveticaNeue';
    font-size: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
}

@media screen and (max-width: 600px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 300px;
        text-align: center;
    }
}
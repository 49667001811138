.row{
    display: flex;
    width: 450px;
    justify-content: space-between;
    margin-right: 29px;
    margin-top: 30px;
}

.rowConsent{
  display: flex;
  width: 50px;
  justify-content: space-between;
  margin-right: 29px;
  margin-top: 30px;
}

.labelText{
  font-family: 'HelveticaNeue';
  font-weight: 200;
  color: #0C1B54;
  font-size: 12px; 
  letter-spacing: 1.8px;
  margin-top: 13px;
}


/* checbox toggle */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 5px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #0E1D51;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #0E1D51;
  }

  input:disabled + .slider {
    box-shadow: #d41515;
  }

  input[type="checkbox"]:disabled + label::before{
    background: #d41515;
  }

  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  @media screen and (max-width: 600px) {
    .row{
      display: flex;
      width: 300px;
      justify-content: space-between;
      margin-right: 29px;
      margin-top: 30px;
      padding-right: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 20px;
  }
  }